
import {
  MailLoginAndAppLogin,
} from "./firebase_auth"

import {
  check_password_comfirm,
  check_password_require,
  check_weak_pasword,
  check_password_rule,
} from "./validates"

$(function () {
  if ($(".registration.new").length <= 0 &&
    $(".registration.create").length <= 0) {
    return;
  }
  check_password_comfirm();
  check_password_require();
  check_weak_pasword();
  check_password_rule();

  $("#create_contractor_form").on("ajax:success", async function (event) {
    var data = event.detail[0];
    if(data['mode'] != "create_user_complete"){
      return;
    }
    console.log("firebaselogin");
    await MailLoginAndAppLogin();
  });
});