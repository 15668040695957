import {
  firebaseStatusCheck,
  checkPhoneNumber,
  mergeUserProvider,
  sendMailVerification,
  checkRecaptcha,
  firebaseError,
  currentUser,
  getAuth,
} from "./firebase_auth"
$(async function () {

  if ($(".registration.auth_tel_and_email").length <= 0) {
    return;
  }
  var prev_user = await currentUser();

  try {

    var auth = await getAuth();
    auth.onAuthStateChanged(async () => {
      await statusCheckAndShowArea();
    });
    
    $(".auth_email_button").on("click", async function () {
      authEmail();
    });

    $(".auth_tel_button").on('click', async function () {
      authPhoneNumber();
      $(".auth_tel_button").hide();
    });

  } catch (error) {
    firebaseError(error);
    reset_view();
    throw error;
  }


  async function statusCheckAndShowArea() {
    // var doneBadge = '<span class="badge badge-pill badge-success">done!</span>'
    var status = await firebaseStatusCheck();
    switch (status.value) {
      case "NOT_LOGIN":
        $("#auth_email_area").hide();
        $("#auth_tel_area").hide();
        // firebaseSignOut();
        window.location = "/users/sign_in/?message=" + status.message
        return;
      case "CONTRACTOR_NOT_AUTH":
        $("#auth_email_area").show();
        $("#auth_tel_area").hide();
        $(".stepBar .email-auth").addClass("current");
        $(".stepBar").css('display', 'flex');
        break;
      case "CONTRACTOR_SHOULD_AUTH_PHONE_NUMBER":
        $("#auth_email_area").hide();
        $("#auth_tel_area").show();
        $(".stepBar .email-auth").addClass("complete");
        $(".stepBar .tel-auth").addClass("current");
        $(".stepBar").css('display', 'flex');
        break;
      case "CONTRACTOR_SHOULD_AUTH_EMAIL":
        $("#auth_tel_area").hide();
        $("#auth_email_area").show();
        $(".stepBar .tel-auth").remove();
        $(".stepBar .email-auth").addClass("current");
        $(".stepBar").css('display', 'flex');
        break;
      case "ALL_AUTHENTICATED":
        $("#auth_email_area").remove();
        $("#auth_tel_area").remove();
        window.location = "/"
        return;
      default:
    }
    $(".edit_user_info_area").show();
    console.log("ステータスチェック")
    return;
  }

  async function authEmail() {
    try {
      // 確認メールを送信
      await sendMailVerification();

      // 表示中の入力欄等すべて非表示、確認メールを送信した旨を表示
      $(".auth_email_button").hide();
      $(".send_mail_message").show();

    } catch (error) {
      firebaseError(error);
      throw error;
    }
  }

  async function authPhoneNumber() {
    try {

      // reCaptcha（ロボット確認チェックボックス）表示
      var result = await do_recaptcha();

      // 電話認証情報を取得
      await get_tel_credential(result);

      // 電話認証情報取得（クライアントによる確認コード入力）後の処理
      $(document).on('end_phone_auth', async function (event, credential) {
        try {
          // firebaseアカウントに電話認証情報紐づけ
          await mergeUserProvider(prev_user, credential);
          $(".completed_phonenum_auth_message").show();
          await statusCheckAndShowArea();

        } catch (error) {
          firebaseError(error);

          // recatcha、確認コード有効期限切れの場合は再度recatcha（ロボット確認チェックボックス）からやり直しさせる
          if (error.code == "captcha-check-failed" || error.code == "auth/code-expired") {
            reset_view();
            result = await do_recaptcha();
          }
          await get_tel_credential(result);

        }
      })
    } catch (error) {
      firebaseError(error);
      reset_view();
      throw error;
    }
  }

  async function get_tel_credential(recaptcha_result) {
    $(".submit_code").on("click", async function () {
      try {
        $(".submit_code").off();
        var code = $(".code").val();
        var credential = checkPhoneNumber(code, recaptcha_result);
        $(document).trigger('end_phone_auth', credential);

      } catch (error) {
        get_tel_credential(recaptcha_result);
        firebaseError(error);
        throw error;
      }
    });
  }

  async function do_recaptcha() {
    var tel = gon.user_info.tel;

    $("#recaptcha-text").show();
    $("#recaptcha-container").show();

    var result = await checkRecaptcha(tel);

    $("#recaptcha-container").hide();
    $("#phone-code-input-container").show();

    return result;
  }


  function reset_view() {
    $("#phone-code-input-container").hide();
    $("#recaptcha-container").empty();
    $("#recaptcha-text").hide();
    $(".code").val("");
    $(".auth_tel_button").show();
  }

  function hide_all() {
    $("#phone-code-input-container").hide();
    $("#recaptcha-container").empty();
    $("#recaptcha-text").hide();
    $(".code").val("");

    $("#authAccordion").hide();
    $("#auth_email_area").hide();
    $("#auth_tel_button").hide();
  }
});