import {
  TextCopy,
} from "../common/textcopy"

$(function () {

  if ($(".cameras.show").length <= 0) {
    return;
  }
  new TextCopy(".copy-button",".copy-element")
});