export {
  disp_open_file,
  disp_open_file_image,
  destroy_file,
  check_max_file_size,
}

function disp_open_file(inputfile_selector, dispdiv_selector) {

  $(inputfile_selector).change(function () {

    // 選択中ファイル名削除
    $(dispdiv_selector).find("span").remove();
    var files = $(this).get(0).files;


    //画像ファイル選択した場合
    if (files.length > 0) {

      // 選択中ファイル名表示
      var filenameTemp = $("<span>")
      filenameTemp.addClass("badge badge-pill badge-info");

      for (var i = 0; i < files.length; i++) {

        var filename = filenameTemp.clone();

        filename.text(files[i].name);
        $(dispdiv_selector).append(filename);

      }
    }
  });
}

function disp_open_file_image(inputfile_selector, dispdiv_selector) {

  var current_file = $(dispdiv_selector).find(".image_preview").clone();

  $(inputfile_selector).change(function () {


    var files = $(this).get(0).files;

    //画像ファイル選択した場合
    if (files.length > 0) {

      // 選択中ファイル表示
      var preview_temp = $("<div>");

      var height = current_file.find("img").attr("height")
      var width = current_file.find("img").attr("width")
      var image_temp = $(`<img height=${height} width=${width} hidden>`);

      var height = current_file.find("video").attr("height")
      var width = current_file.find("video").attr("width")
      var video_temp = $(`<video height=${height} width=${width} controls="controls" hidden>`);


      // 選択中ファイルプレビュー削除
      $(dispdiv_selector).find(".image_preview").remove();

      preview_temp.addClass("image_preview");
      preview_temp.append(image_temp);
      preview_temp.append(video_temp);

      for (var i = 0; i < files.length; i++) {

        var preview = preview_temp.clone();

        var image_url = window.URL.createObjectURL(files[i]);

        if (gon.image_content_types.includes(files[i].type)){
          preview.find("img").attr("src", image_url).removeAttr("hidden");
        }else if (gon.video_content_types.includes(files[i].type)){
          preview.find("video").attr("src", `${image_url}#t=0.001`).removeAttr("hidden");
        }

        $(dispdiv_selector).append(preview);

      }
      // 画像ファイル選択されていない　かつ　登録済みの画像が存在する場合
    } else if (current_file.length > 0) {
      // 選択中ファイルプレビュー削除
      $(dispdiv_selector).find(".image_preview").remove();
      // 登録済み画像を表示
      $(dispdiv_selector).append(current_file.clone());
    }
  });
}

function destroy_file(inputfile_selector, dispdiv_selector, destroy_btn_selector, destroy_flg_field) {
  
  $(destroy_btn_selector).on('click', function(){
    // 選択中ファイルプレビュー削除
    $(dispdiv_selector).find(".image_preview").remove();

    // 選択中ファイル削除
    $(inputfile_selector).val("");

    // hiddenの削除フラグオン
    $(destroy_flg_field).val(true);
  })
}
function check_max_file_size(inputfile_selector, max_size_mb) {

  $(inputfile_selector).on("change", function(){
    //サイズ計算
    var total_size = 0;
    var files = $(this).prop('files')
  
    if (files.length > 0) {
        $.each(files, function () {
            total_size = total_size + this.size
        });
    }
  
    //20MBを越えた場合はアラート表示
    if (max_size_mb * 1000000 < total_size) {
        alert('選択されたファイルサイズが20MBを超えています。ファイルを圧縮するか減らしてください。');
        $(this).val("").trigger("change");
    }
  });
}