import {
  is_display
} from "../common/ui"

$(function(){
  if(!is_display(".live.index") ){
    return;
  }
  $("#id").on('change', function(){
    $(this).parent()[0].submit();
  })

});