import {
  sendPasswordResetEmail,
  firebaseError
} from "./firebase_auth"
import {
  disp_alert
} from "../common/ui"
$(async function () {

  if ($(".email.password_reminder").length <= 0) {
    return;
  }

  $(".submit").on('click', async function () {
    try {
      var email = $('.email_input').val();
      await sendPasswordResetEmail(email);
      disp_alert("パスワードリマインドメールを送信しました。");
    } catch (error) {
      firebaseError(error);
    }
  })

});