import {
  check_max_file_size,
  disp_open_file_image
} from "../../common/file_upload"
import {
  is_display
} from "../../common/ui"

$(function () {
  if(!is_display(".ActivityRecord.records.edit") &&
  !is_display(".ActivityRecord.records.update")){
      return;
  }
  check_max_file_size("#activity_record_image", 25);
  disp_open_file_image("#activity_record_image", ".image");
  
});