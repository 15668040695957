$(function () {
  if ($(".video.show").length <= 0) {
    return;
  }
  // let url = new URL(window.location.href);
  // let params = url.searchParams;

  // var player = videojs($("video")[0]);

  const startThreshold = 3000 //ms
  const endThreshold = 6000 //ms
  
  var enterTime = Date()
  var exitTime = Date()
  var videoStartTime = Date()
  var videoEndTime = Date()

  if (gon.enter_time){
    enterTime = new Date(gon.enter_time)
  }
  if (gon.exit_time){
    exitTime = new Date(gon.exit_time)
  }
  if (gon.video_start_time){
    videoStartTime = new Date(gon.video_start_time)
  }
  if (gon.video_end_time){
    videoEndTime = new Date(gon.video_end_time)
  }

  var startTime = enterTime - videoStartTime - startThreshold

  if (startTime > 0){
    setVideoStartTime(startTime)
  }
  
  var endTime = exitTime - videoStartTime + endThreshold
  setVideoEndTime(endTime)

  function setVideoStartTime(ms){
    var time = ms / 1000
    var video = $("video")[0]
    video.currentTime = time;
  }

  function setVideoEndTime(ms){
    var time = ms / 1000
    var video = $("video")
    video.on("timeupdate", function(){
      if(this.currentTime >= time){
        this.pause();
      }
    })
  }
});
