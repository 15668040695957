export {
  TextCopy
}
class TextCopy {

  constructor(copyButtonSelector, copyTextSelector) {

    this.copyButtonElement = $(copyButtonSelector);
    this.copyTextElement = $(copyTextSelector);
    this.copy();

  }

  copy(){
    this.copyButtonElement.on("click", function(){
      var copyText = "";
      if(this.copyTextElement.text()){
        copyText = this.copyTextElement.text();
      } else if(this.copyTextElement.val()){
        copyText = this.copyTextElement.val();
      }
      navigator.clipboard.writeText(copyText);

    }.bind(this))
  }

}