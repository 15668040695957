export {
    disp_spinner_button,
    disp_flashing,
    // disp_message,
    // disp_messages,
    disp_date_hr_for_area,
    disp_date_hr,
    disp_add_form,
    is_display,
    disp_alert,
}
// import {
//     CreateVideo
// } from "../video_chat/examination_support/createVideo";

/**
 * is_disabledがfalseのとき、ボタンにスピナーボタンを追加します。
 * @param {*} button_selector 
 * @param {*} is_disabled 
 * @param {*} submit_timeout ※※呼び出し元で戻り値を保持し、次の呼び出し時に引数として設定してください。初回呼び出し時の引数はnullにしてください。
 */
function disp_spinner_button(button_selector, is_disabled) {

    var button = $(button_selector);
    var spinner = $(".spinner");

    if (spinner.length <= 0) {
        spinner = button.clone().hide();
        spinner.removeAttr("id").addClass("spinner").attr("disabled", "true");
        spinner.find("img").remove();
        var spinner_image = $("<span>").addClass("spinner-border spinner-border-sm");
        spinner.append(spinner_image);
        button.after(spinner);
    }

    // 非活性の場合
    if (is_disabled == true) {
        button.hide();
        spinner.show();

        // 活性の場合
    } else {
        spinner.hide();
        button.show();
    }
}

/**
 * ボタンを点滅させます。
 * @param {*} inputfile_selector 
 * @param {*} button_selector 
 * @param {*} stop_selector 
 */
function disp_flashing(inputfile_selector, button_selector, stop_selector) {

    var button = $(button_selector);
    var interval = null;

    // 点滅終了
    if (interval != null) {
        clearInterval(interval);
    }

    $(inputfile_selector).change(function () {

        // 点滅終了
        if (interval != null) {
            clearInterval(interval);
        }

        //画像ファイル選択した場合
        if ($(this).get(0).files.length > 0) {

            // デザイン変更
            button.addClass("btn-danger");
            button.removeClass("btn-primary");

            // 点滅開始
            interval = setInterval(function () {
                button.fadeOut(500, function () {
                    $(this).fadeIn(500)
                });
            }, 1000);

            // 画像ファイルが存在しない場合
        } else {
            // デザイン変更
            button.addClass("btn-primary");
            button.removeClass("btn-danger");
        }
    });

    $(stop_selector).click(function () {
        // 点滅終了
        if (interval != null) {
            clearInterval(interval);
        }
        // デザイン変更
        button.addClass("btn-primary");
        button.removeClass("btn-danger");
    })

}

// function disp_messages(messages, message_area) {
//     var message_doms = []
//     if (message_area) {
//         messages.forEach(function (message) {
//             message_doms.push(disp_message(message, message_area))
//         });

//     } else {
//         messages.forEach(function (message) {
//             message_doms.push(disp_message(message))
//         });
//     }
//     return message_doms
// }

// function disp_message(message, message_area) {

//     // テンプレートのコピー
//     var message_temp = $(".message.temp").clone().removeClass("temp");

//     // メッセージ内容表示
//     message_temp.find(".channel_name").text(message.channel.name); //チャンネル名
//     var email = "削除済みユーザ";
//     if (message.user) {
//         email = message.user.email
//     }
//     message_temp.find(".user").text(email); // メールアドレス
//     message_temp.find(".message_body").text(message.body); // メッセージ内容
//     message_temp.find(".message_content").attr("data-message_id", message.id); // メッセージIDをdata属性として付与
//     message_temp.find(".message_content").attr("data-parent_message_id", message.parent_message_id); // 親（リプライ元）メッセージIDをdata属性として付与
//     message_temp.find(".date").attr("data-year", message.create_year).attr("data-day", message.create_day).attr("data-hour", message.create_hour); // メッセージ日付をdata属性として付与
//     message_temp.find(".year").text(message.create_year);
//     message_temp.find(".day").text(message.create_day);
//     message_temp.find(".hour").text(message.create_hour);
//     message_temp.find(".message_id").attr("value", message.id);

//     // メッセージ画像表示
//     if (message.image_url) {
//         var img = $("<img>").attr("src", message.image_url);
//         message_temp.find(".message_image").attr("href", message.image_url).append(img);
//     }
    
//     // 見守り画像表示
//     if (message.pet_images_url){
//         var pet_images_link = $("<a class='videoLink'>").text("動画をみる").on("click", function(){
//             var spinner = $("<div class='spinner-border spinner'>");
//             pet_images_link.append(spinner);
//             CreateVideo.init(message.pet_images_url, 500).then((result)=>{
//                 result.showVideoInModal("#modalTemp");
//                 pet_images_link.find(".spinner").remove();
//             })
            
//         })
//         message_temp.find(".message_image").append(pet_images_link);
//     }

//     //　編集済み表示
//     if (message.updated) {
//         var updated = message_temp.find(".updated");
//         updated.text("(" + "編集済み " + message.updated_at + ")").removeClass("hidden");
//     }


//     var reply_link = message_temp.find(".reply_link");
//     if (message.has_reply) {
//         // reply_link.find(".hide_reply_link").hide();
//         reply_link.show();
//     } else {
//         reply_link.hide();
//     }

//     // タグ表示
//     var message_tags = message.message_tag
//     message_tags.forEach(function (message_tag) {
//         var tag_name = $("<span>").text(message_tag.tag.name).addClass("tag badge badge-pill badge-info").attr("data-tag_id", message_tag.tag.id);
//         message_temp.find(".message_tags").append(tag_name);
//     });

//     // メッセージリンク化
//     if (message_temp.prop("tagName") == "A") {
//         var query = "?messageid=" + message.id;
//         if (message.parent_message_id) {
//             query = query + "&parentid=" + message.parent_message_id
//         }
//         var url = "/chat/" + message.channel.id + "/chat/";

//         message_temp.attr("href", url + query);
//     }



//     if (message_area) {
//         $(message_area).append(message_temp);
//     }

//     return message_temp;

// }

function disp_date_hr_for_area(message_area) {
    message_area.find(".datehr").remove();

    message_area.find(".message").each(function () {
        disp_date_hr($(this));
    })

}

function disp_date_hr(message) {

    var prev_message = $(message).prevAll(".message").first();

    var prev_date = prev_message.find(".date");
    var prev_year = prev_date.data("year");
    var prev_day = prev_date.data("day");

    var date = $(message).find(".date");
    var year = date.data("year");
    var day = date.data("day");

    if (prev_year + prev_day != year + day) {
        if (!year || !day) {
            return;
        }
        var text = $("<span>").text(year + day);
        var date_hr = $("<div>").addClass("datehr").append("<hr>").append(text);

        $(message).before(date_hr);
    }

}

function disp_add_form(plus_button_selector, form_temp_selector, form_area_selector, max_form_count = 10, input_name = "input", minus_button_selector = "", ) {

    $(plus_button_selector).click(function () {

        var form_count = $(form_area_selector).find(".added_form").length
        if (form_count >= max_form_count) {
            return;
        }

        var form = $(form_temp_selector).clone();
        var input = form.find("input,select,textarea");


        if (input.length == 1) {
            var name = input_name + "[]"
            $(input).attr("name", name).attr("required", true);

        } else if (input.length > 1) {
            var input_count = 0;
            input.each(function () {
                input_count++;
                var id = input_name + "_" + form_count + "_" + input_count;
                $(this).attr("id", id).attr("name", id).attr("required", true);
            });
        }
        if (minus_button_selector) {
            form.find(minus_button_selector).on('click', function () {
                $(this).parent().remove();
            });
        }
        form.removeClass(form_temp_selector.replace(/\./, ''));
        form.addClass("added_form")

        $(form_area_selector).append(form);
        form.removeClass("hidden");

    });

    $(minus_button_selector).on('click', function () {
        $(this).parent().remove();
    });
}

function is_display(body_class_name) {
    return $(body_class_name).length > 0
}

function disp_alert(body, prepend_element_selector, style) {

    if (!prepend_element_selector) {
        prepend_element_selector = $(".wrapper");
    }
    $(".alert").remove();

    var alert = $(".alert-temp").clone();
    alert.addClass("alert");
    alert.find(".alert-body").text(body);
    $(prepend_element_selector).prepend(alert);

    if (style) {
        alert.attr('style', style);
    }


    $(alert).show();

}


// $(function () {
//     $('.datepicker').datepicker({
//         dateFormat: "yy/mm/dd"
//     });

// });