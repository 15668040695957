import {
  is_display
} from "../../common/ui"

$(function(){
  if(!is_display(".ActivityRecord.records.index") ){
    return;
  }

  showImages();

  $(window).on('scroll', function() {
    showImages();
  });

  $("#month").on('change', function(){
    $(this).parent()[0].submit();
  })

  function showImages(){
    var scrollHeight = $(document).height();
    var scrollPosition = $(window).height() + $(window).scrollTop();
    if ( (scrollHeight - scrollPosition) / scrollHeight <= 0.05) {
      $('.jscroll').jscroll({
        contentSelector: '.images',
        nextSelector: '.pagination:last a.next'
      });
    }
  }

});