import { getFirebaseConfig } from "./firebase_config";
import firebase from "firebase/app";

export {
  firebaseInit,
  firebase
};

require("firebaseui");

const firebaseConfig = getFirebaseConfig();

const firebaseInit = (config) => {
  if (app) {
    return;
  }
  if (!config) {
    config = firebaseConfig;
  }
  // Initialize Firebase
  var app = firebase.initializeApp(config);
};
