import {
  is_display,
} from "../common/ui"
import {
  Calendar
} from "../common/calendar"

$(function () {
  if (!is_display(".count_tag.index")) {
    return;
  }

  $("#date").on('change', () => {
    $(this).closest("form").trigger('submit');
  })

  var calendar = new Calendar(gon.date);
  $(".calendar-area").append(calendar.getCalendar());
  $(".calendar-year-date").append(calendar.getYearAndMonth())

  $('#prev').on('click', () => {
    $("#calendar").remove();
    calendar.moveCalendar("prev");
    $(".calendar-area").append(calendar.getCalendar());
  });

  $('#next').on('click', () => {
    $("#calendar").remove();
    calendar.moveCalendar("next");
    $(".calendar-area").append(calendar.getCalendar());
  });

});