import {
  check_password_comfirm,
  check_weak_pasword,
  check_password_rule,
} from "./validates"

$(function () {
  if ($(".registration.edit").length <= 0 &&
    $(".registration.update").length <= 0) {
    return;
  }
  check_password_comfirm();
  check_weak_pasword();
  check_password_rule();
});