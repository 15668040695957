export {
  Calendar
}
class Calendar {

  constructor(standardDate) {

    this.weeks = ['日', '月', '火', '水', '木', '金', '土'];
    if (standardDate){
      this.date = new Date(standardDate);
    } else {
      this.date = new Date();
    }
    this.year = this.date.getFullYear();
    this.month = this.date.getMonth() + 1;
    this.calendar = this.createCalendar(this.year, this.month);

  }

  getCalendar(){
    return this.calendar;
  }

  getYearAndMonth(){
    // 作成するカレンダーの年月を表示
    let yearAndMonth = '<span class="year-month">' + this.year  + '/' + this.month + '</span>';
    return yearAndMonth;
  }

  createCalendar(year, month) {
    const startDate = new Date(year, month - 1, 1); // 月の最初の日を取得
    const endDate = new Date(year, month, 0); // 月の最後の日を取得
    const endDayCount = endDate.getDate(); // 月の末日
    const lastMonthEndDate = new Date(year, month - 2, 0); // 前月の最後の日の情報
    const lastMonthendDayCount = lastMonthEndDate.getDate(); // 前月の末日
    const startDay = startDate.getDay(); // 月の最初の日の曜日を取得
    let dayCount = 1; // 日にちのカウント
    let calendarHtml = $('<div>').attr("id", "calendar"); // HTMLを組み立てる変数
    let calendarTable = $('<table>'); // HTMLを組み立てる変数


    // 曜日の行を作成
    $.each(this.weeks, (i, week)=>{
      calendarTable.append('<td>'+ week + '</td>');
    })

    let rowLength = 6; // 表示するカレンダーの行数

    for (let w = 0; w < rowLength; w++) {
      let row = $("<tr>");
      let oneWeekLength = 7; // 一週間の日数

      for (let d = 0; d < oneWeekLength; d++) {
        if (w == 0 && d < startDay) {
          // 1行目で1日の曜日の前
          let num = lastMonthendDayCount - startDay + d + 1;
          row.append('<td class="is-disabled">' + num + '</td>');
        } else if (dayCount > endDayCount) {
          // 末尾の日数を超えた
          let num = dayCount - endDayCount;
          row.append('<td class="is-disabled">' + num + '</td>');
          dayCount++;
        } else {
          let dataDate = year + "-" + month + "-" + dayCount;
          let link = $("<a>").text(dayCount).attr("href", "/count_tag/" + gon.monitoring_id + "/index/?date=" + dataDate );
          let td = $("<td class='calendar_td'>").data("date", dataDate).append(link);
          row.append(td);
          dayCount++
        }
      }
      calendarTable.append(row);
    }

    calendarHtml.append(calendarTable);


    return calendarHtml;
  }

  moveCalendar(move) {

    let january = 1;
    let december = 12;
    if (move === 'prev') {
      this.month--;

      if (this.month < january) {
        this.year--;
        this.month = december;
      }
    }

    if (move === 'next') {
      this.month++;

      if (this.month > december) {
        this.year++;
        this.month = january;
      }
    }

    this.calendar = this.createCalendar(this.year, this.month);
  }


}