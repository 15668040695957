import {
  FirebaseChat
} from "./firebase_chat";
import {
  is_display
} from "../common/ui"

$(function () {

  if(!is_display(".chat.index")){
      return;
  }

  const messageAreaSelector = ".message-area"
   const firebasechat = new FirebaseChat(messageAreaSelector);

  $(".chatsubmit").on("click", ()=>{
    firebasechat.submit($("#messagebody").val(), $("#tag").val());
    $("#messagebody").val("");
    $("#tag").val("");
  })

  $('.tag-select2').select2({
    width: '100%',
    placeholder: "タグ選択"
  })
  $('.tag-select2').next(".select2").find("input").attr("readonly", "true");

});
