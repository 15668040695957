import {
  check_password_comfirm,
  check_weak_pasword,
  check_password_rule,
} from "./validates"

$(function () {
  if ($(".registration.new_other_user").length <= 0 &&
    $(".registration.create_other_user").length <= 0) {
    return;
  }
  check_password_comfirm();
  check_weak_pasword();
  check_password_rule();
});