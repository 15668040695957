import { firebaseInit, firebase } from "../firebase/firebase_main";
import "@firebase/firestore";
export { FirebaseChat };

class FirebaseChat {
  constructor(messageAreaSelector) {

    this.ref = firebase.firestore().collection(gon.monitoring + "item");
    this.receive();
    this.messageArea = $(messageAreaSelector)
    this.receiveNewMessage = false
  }

  init() {
    this.ref.once("value").then(
      (snapshot) => {

        snapshot.forEach((doc) => {
          this.messageArea.append(this.message(doc));
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  receive() {
    this.ref.onSnapshot(
      (snapshot) => {
        if (!snapshot) {
          return;
        }
        snapshot.docChanges().forEach((change) => {
          // 追加
          if ( change.type === 'added' ) {
            this.messageArea.append(this.message(change.doc));
          }
          if (change.type === "modified") {
            this.messageArea.find(`#${change.doc.id}`).replaceWith(this.message(change.doc))
            console.log("Modified: ", change.doc.data());
          }
          if (change.type === "removed") {
            console.log("Removed: ", change.doc.data());
          }
        });
        
      },
      (error) => {
        console.log(error);
      }
    );
  }

  message(doc) {

    let id = doc.id;
    let data = doc.data();

    // テンプレートのコピー
    var message_temp = $(".message.temp").clone().removeClass("temp");

    message_temp.find(".user").text(data["username"]); // ユーザ名

    message_temp.find(".message_body").text(data["content"]); // メッセージ内容

    message_temp.attr("id", id);

    if (data["datetime"]){
      var date = data["datetime"].toDate();
      message_temp.find(".date").attr("data-year", date.getFullYear()).attr("data-day", (date.getMonth()+1) + date.getDate()).attr("data-hour", date.getTime()); // メッセージ日付をdata属性として付与
      message_temp.find(".date").text(this.formatDate(date, "YYYY年MM月DD日 hh時mm分"))
    }


    // タグ表示
    if(data["tags"]){
      var message_tags = data["tags"];
      message_tags.forEach(function (message_tag) {
        var tag_name = $("<span>")
          .text(message_tag)
          .addClass("tag badge badge-pill badge-info")
        message_temp.find(".message_tags").append(tag_name);
      });
    }

    return message_temp

  }

  submit(content, tags) {
    if (!content && !tags) return;
    this.ref.add({
      datetime: firebase.firestore.FieldValue.serverTimestamp(),
      content: content,
      tags: tags,
      username: gon.username,
    });
  }

    formatDate(date, format) {

      format = format.replace(/YYYY/, date.getFullYear());
      format = format.replace(/MM/, date.getMonth() + 1);
      format = format.replace(/DD/, date.getDate());
      format = format.replace(/hh/, date.getHours());
      format = format.replace(/mm/, date.getMinutes());
      format = format.replace(/ss/, date.getSeconds());

      return format;
  }
}
