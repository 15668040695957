// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "../stylesheets/application";

import "user/firebase_auth";
import "user/login";
import "user/registrations_new";
import "user/registrations_edit";
import "user/registrations_new_other_user";
import "user/check_email";
import "user/password_reminder";
import "user/auth_tel_and_email";
import "chat/chat";
import "video/show";
import "cameras/show";
import "activity_record/records/new";
import "activity_record/records/edit";
import "activity_record/records/index";
import "count_tag/index";
import "live/index"

import "common/ui";
import "common/calendar";

import "bootstrap";
import "popper.js";
import "select2";
import videojs from 'video.js';
import jscroll from 'jscroll';

import "../stylesheets/application";
Rails.start();
ActiveStorage.start();

global.$ = $;

import { firebaseSignOut } from "../user/firebase_auth";
import {
  check_max_file_size,
} from "../common/file_upload"

$(function () {
  $("#signout").on("click", function () {
    firebaseSignOut();
  });

  $(".js-select2").select2();
  check_max_file_size("#activity_record_image", 25);
  $(".take-photo-input").on('change', function () {
    var files = $(this).get(0).files;
    if (files.length <= 0) { return; }

    var form = $(this).closest("form");
    $(form).trigger("submit");

  });
  

});
